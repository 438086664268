import ReactPlayer from "react-player"
import './Video.scss'
import {useEffect, useState} from "react";
import {PlayVideo} from "../Icons/Icons";

export const Video = (videoUrl, previewUrl) => {
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(true);

  return (
    <div
      className='video-wrap'
      onClick={() => {
        setPlaying((prev)=> !prev);
        setMuted(false);
      }
    }>
      <div className={playing ? 'play-icon hide' : 'play-icon'}>
        <PlayVideo />
      </div>
      <ReactPlayer
        url={videoUrl.videoUrl}
        muted={muted}
        controls={true}
        loop={true}
        playing={playing}
        onReady={() => setPlaying(true)}
        onPause={() => setPlaying(false)}
        onPlay={() => setPlaying(true)}
      />
    </div>
  )
}