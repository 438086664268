import './Trial.scss';
import promoImg from './Content/promoImg.png'
import trialImg from './Content/trial.png'

export default function Trial() {
  return (
    <>
      <div className="trial" id='trial'>
        <div className="wrap">
          <div className="content">
            <div className="sub-title">
              Try us for 14 days, money back guarantee
            </div>
            <h2 className="title">
              Unlimited editing on any <br/> subscription package trial for 14 days
            </h2>
            <img src={promoImg} alt="" className="promo-image"/>
            <div className="text">
              We are so confident you will love our editing services, sign up today for a 14 day trial! <br/>
              If you don't love using our services after 14 days, simply cancel and we’ll give you money back
            </div>
            <a href="#contact" className="btn">
              Start Now!
            </a>
          </div>
          <img src={trialImg} alt="" className="trial-image"/>
        </div>
      </div>
    </>
  )
}