export const Menu = () => (
  <ul>
    <li>
      <a href="/#pricing">
        Pricing
      </a>
    </li>
    <li>
      <a href="/#how-it-works">
        How it works
      </a>
    </li>
    <li>
      <a href="/#portfolio">
        Portfolio
      </a>
    </li>
    <li>
      <a href="/#faq">
        FAQ
      </a>
    </li>
    <li>
      <a href="/#contact">
        Book a Call
      </a>
    </li>
  </ul>
)