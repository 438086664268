import './UserDashboard.scss'
import tokenServices from "../../services/tokenServices";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import api from "../../API/Services/api";
import {Logout} from "../../Components/Icons/Icons";

export default function UserDashboard() {
  const navigate = useNavigate();
  const [user, setUser] = useState('');
  const logout = () => {
    tokenServices.removeUser();
    navigate("/");
  }

  useEffect(() => {
    api.get('/users/account').then(res => setUser(res.data));
  })

  return (
    <section className='user-dashboard'>
      <div className="wrap">
        <div className="user-dashboard__top">
          <h1 className="title">
           <span>{user.name}</span> Dashboard
          </h1>
          <div
            className="logout"
            onClick={() => logout()}
          >
            Logout <Logout/>
          </div>
        </div>
      </div>
      <iframe src={user.iframe}></iframe>
    </section>
  )
}