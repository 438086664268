import {AboutTextFirst, AboutTextSecond} from "./AboutText";
import imageFirst from "./Content/about-us.png"
import imageSecond from "./Content/about-us2.png"

export default function AboutItems() {
  return (
    <div className="about-us__items">
      <div className="about-us__item">
        <div className="text">
          <AboutTextFirst/>
        </div>
        <img src={imageFirst} alt="the creators of ezedit"/>
      </div>
      <div className="about-us__item">
        <div className="text">
          <AboutTextSecond/>
        </div>
        <img src={imageSecond} alt="the creators of ezedit"/>
      </div>
    </div>
  )
}