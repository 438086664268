import UsersTableHead from "./UsersTableHead";
import User from "./User";
import {getUsers} from "../../../API/Users/Users";
import {useEffect, useState} from "react";

export default function UsersTable() {
  const [usersData, setUserData] = useState([]);

  useEffect(()=>{
    getUsers().then(res => setUserData(res.data));
  },[])

  return(
    <>
      <div className="users">
        <UsersTableHead/>
        {usersData.map((user, index) => (
          <User
            user={user}
            key={user.name + index}
            index={index}
          />
        ))}
      </div>
    </>
  )
}