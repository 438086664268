import './TermsAndConditions.scss'

export default function TermsAndConditions() {
  return (
    <section className='terms-page'>
      <div className="wrap">
        <h1 className="title">
          Terms & Conditions
        </h1>
        <div className="date">
          Updated January 16 2023
        </div>
        <div className="content">
          <p>
            <strong>PLEASE READ THE FOLLOWING TERMS AND CONDITIONS OF USE CAREFULLY BEFORE USING THIS
              WEBSITE</strong> (ezedit.com). All
            users of this site agree that access to and use of this site is subject to the following terms and
            conditions and other applicable law. If you do not agree to these terms and conditions, please do not use
            this site. We reserve the right to update this policy at any time.
            Welcome to EzEdit’s website and online services <strong>(“EzEdit”, “us”, “we”, or “our”)</strong>, owned and
            operated by NYB
            Media Inc <strong>(“NYB”)</strong>. This page explains the terms by which you may use our online services
            and website. By
            accessing or using our website, you agree to be bound by these Terms and Conditions and to the collection
            and use of your information as set forth in our Privacy Policy. This agreement applies to all visitors and
            account holders who access our website.
          </p>
          <h2 className="title">
            Copyright
          </h2>
          <p>
            The entire content included in this site, including but not limited to text, graphics or code is copyrighted
            as a collective work under the laws of Canada, the United States and other copyright laws, and is the
            property of NYB. Copyright 2023, NYB MEDIA INC. ALL RIGHTS RESERVED. You may display and, subject to any
            expressly stated restrictions or limitations relating to specific material, download or print portions of
            the material from the different areas of the site solely for your own non-commercial use, or to place an
            order or subscription with EzEdit. Any other use, including but not limited to the reproduction,
            distribution, display or transmission of the content of this site is strictly prohibited, unless authorized
            by NYB. You further agree not to change or delete any proprietary notices from materials downloaded from the
            site.
          </p>
          <h2 className="title">
            Trademarks
          </h2>
          <p>
            All trademarks, service marks and trade names of EzEdit used in the site are trademarks or registered
            trademarks of NYB.
          </p>
          <h2 className="title">
            Warranty Disclaimer
          </h2>
          <p>
            This site and materials on this site are provided "as is" and without warranties of any kind, whether
            express or implied. To the fullest extent permissible pursuant to applicable law, EzEdit disclaims all
            warranties, express or implied, including, but not limited to, implied warranties of merchantability and
            fitness for a particular purpose and non-infringement. EzEdit does not represent or warrant that the
            functions contained in the site will be uninterrupted or error-free, that the defects will be corrected, or
            that this site or the server that makes the site available are free of viruses or other harmful components.
            EzEdit does not make any warrantees or representations regarding the use of the materials in this site in
            terms of their correctness, accuracy, adequacy, usefulness, timeliness, reliability or otherwise. Some
            Provinces or States do not permit limitations or exclusions on warranties, so the above limitations may not
            apply to you.
          </p>
          <h2 className="title">
            Typographical Errors
          </h2>
          <p>
            In the event that any subscription service for purchase is mistakenly listed at an incorrect price, EzEdit
            reserves the right to refuse or cancel any such order(s) listed at the incorrect price. EzEdit reserves the
            right to refuse or cancel any such order(s) whether or not the order has been confirmed and your credit card
            charged. If your credit card has already been charged for the purchase and your order is cancelled, EzEdit
            shall issue a credit to your credit card account in the amount of the incorrect price.
          </p>
          <h2 className="title">
            Term and Termination
          </h2>
          <p>
            These terms and conditions are applicable to you upon your accessing the site and/or completing the
            registration or purchasing process. These terms and conditions, or any part of them, may be terminated by
            EzEdit without notice at any time, for any reason. The provisions relating to Copyright, Trademark,
            Disclaimer, Limitation of Liability, Indemnification and Miscellaneous shall survive any termination.
          </p>
          <h2 className="title">
            Use of Website
          </h2>
          <p>
            Harassment in any manner or form on the site is strictly forbidden. Impersonation of others, including
            EzEdit and/or its employees or representatives, as well as other visitors on the site, is prohibited
          </p>
          <h2 className="title">
            Participation Disclaimer
          </h2>
          <p>
            EzEdit does not and cannot review all communications and materials posted to or created by users accessing
            the site, and is not in any manner responsible for the content of these communications and materials. You
            acknowledge that by providing you with the ability to view content on the site, EzEdit is not undertaking
            any obligation or liability relating to any contents or activities on the site. However, EzEdit reserves the
            right to block or remove communications or materials that it determines to be (a) abusive, defamatory, or
            obscene, (b) fraudulent, deceptive, or misleading, (c) in violation of a copyright, trademark or; other
            intellectual property right of another or (d) offensive or otherwise unacceptable to EzEdit in its sole
            discretion
          </p>
          <h2 className="title">
            Indemnification
          </h2>
          <p>
            You agree to indemnify, defend, and hold harmless NYB, its affiliated, related and subsidiary entities, and
            each of its and their respective officers, directors, employees, agents, licensors, licensees and assigns,
            from and against all losses, expenses, damages and costs, including reasonable legal fees, resulting from:
            (i) your use of and access to the EzEdit website, including any data or content transmitted or received by
            you; (ii) your violation of any term of this agreement, including without limitation your breach of any of
            the representations and warranties above; (iii) your violation of any third-party right, including without
            limitation any right of privacy, publicity, or intellectual property rights; and/or (iv) your violation of
            any applicable law, rule, or regulation.
          </p>
          <h2 className="title">
            Limitation of Liability
          </h2>
          <p>
            To the maximum extent permitted by applicable law, NYB assumes no liability or responsibility for any (i)
            errors, mistakes, or inaccuracies of content; (ii) personal injury or property damage, of any nature
            whatsoever, resulting from your access to or use of our service and/or purchasing subscriptions
            (collectively, the “Service”); (iii) any unauthorized access to or use of our secure servers and/or any and
            all personal information stored therein; (iv) any interruption or cessation of transmission to or from the
            Service; (v) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our Service
            by any third party; and/or (vi) any errors or omissions in any content posted, emailed, transmitted, or
            otherwise made available through the Service. In no event shall NYB, its affiliated, related and subsidiary
            entities, and each of its and their respective officers, directors, employees, agents, licensors and
            suppliers be liable to you for any claims, proceedings, liabilities, obligations, damages, losses or costs
            in an amount exceeding the amount you paid to NYB hereunder or $100.00, whichever is greater. No action,
            regardless of form or nature, arising out of this agreement may be brought by or on behalf of you more than
            one (1) year after the cause of action first arose.
          </p>
          <p>
            This limitation of liability section applies whether the alleged liability is based on contract, tort,
            negligence, strict liability, or any other basis, even if NYB has been advised of the possibility of such
            damage. The foregoing limitation of liability shall apply to the fullest extent permitted by law in the
            applicable jurisdiction.
          </p>
          <h2 className="title">
            Third Party Links
          </h2>
          <p>
            In an attempt to provide increased value to our visitors, EzEdit may link to sites operated by third
            parties. However, even if the third party is affiliated with NYB, NYB/EzEdit has no control over these
            linked sites, all of which have separate privacy and data collection practices, independent of NYB. These
            linked sites are only for your convenience and therefore you access them at your own risk. Nonetheless,
            NYB/EzEdit seeks to protect the integrity of its website and the links placed upon it and therefore requests
            any feedback on not only its own site, but for sites it links to as well (including if a specific link does
            not work).
          </p>
          <h2 className="title">
            Notice
          </h2>
          <p>
            EzEdit may deliver notice to you by means of e-mail, a general notice on the site, or by other reliable
            method to the address you have provided to EzEdit.
          </p>
          <h2 className="title">
            Miscellaneous
          </h2>
          <p>
            Your use of this site shall be governed in all respects by the laws of the Province of Ontario and under the
            federal laws of Canada applicable therein. You agree that jurisdiction over and venue in any legal
            proceeding directly or indirectly arising out of or relating to this site and/or any subscription service(s)
            purchased by you shall be in the courts located in the City of Toronto.
            These terms and conditions, as well as all applicable policies, shall constitute the entire agreement
            between you and NYB concerning the use of our website. If any provision of this agreement is deemed invalid
            by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the
            remaining provisions of this agreement, which shall remain in full force and effect.
            No waiver of any term of this agreement shall be deemed a further or continuing waiver of such term or any
            other term, and NYB’s failure to assert any right or provision under this agreement shall not constitute a
            waiver of such right or provision.
          </p>
          <p>
            Please contact us at <a href="mailto:info@ezedit.com">info@ezedit.com</a> with any questions regarding this
            agreement.
          </p>
          <h2 className="title">
            We reserves the right to modify our terms and conditions at any time. By using our website you agree to all
            of our Terms and Conditions.
          </h2>
          <p>
            Please also visit our <a href="#">Privacy Policy</a> section which applies to information collected through
            our website.
          </p>
        </div>
      </div>
    </section>
  )
}