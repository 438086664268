import {Export, Medal, Message, Scissor, Video} from "../../../../../Components/Icons/Icons";
import {EzEditData} from "../EzEditData";
import TileItem from "./TileItem";

export default function Tile() {
  return (
    <div className="tile">
      {EzEditData.map((item, index) => (
        <TileItem
          text={item.text}
          key={`Tile${index}`}
        >
          {item.icon}
        </TileItem
        >
      ))}
    </div>
  )
}