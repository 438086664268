import {Tab} from "./Tab";
import {PricingPrice} from "./PricingPrice";
import {useState} from "react";

export default function PricingItemVariable({variations, title, frequency, info, time}) {
  const [activeVariation, setActiveVariation] = useState(0);

  return (
    <div className="pricing__item pricing__item_variable">
      <div className="pricing__head">
        <h2 className="title">
          {title}
        </h2>
      </div>
      <div className="pricing__body">
        {/*<div className="tabs">*/}
        {/*  {variations.map((item, index) => (*/}
        {/*      <Tab*/}
        {/*        text={item.name}*/}
        {/*        key={`priceTab${index}`}*/}
        {/*        index={index}*/}
        {/*        active={activeVariation === index}*/}
        {/*        setActiveVariation={setActiveVariation}*/}
        {/*      />*/}
        {/*    )*/}
        {/*  )}*/}
        {/*</div>*/}
        {/*<div className="pricing__prices">*/}
        {/*  {variations.map((item, index) => (*/}
        {/*      <PricingPrice*/}
        {/*        price={item.price}*/}
        {/*        key={`pricesPrice${index}`}*/}
        {/*        active={activeVariation === index}*/}
        {/*      />*/}
        {/*    )*/}
        {/*  )}*/}
        {/*</div>*/}
        {/*<div className="pricing__frequency">*/}
        {/*  {frequency}*/}
        {/*</div>*/}
        <div className="text">
          <div className="pricing__info">
            <span>
              what you get
            </span>
            <p>
              {info}
            </p>
          </div>
          <div className="pricing__time">
            <span>
              turnaround time
            </span>
            <p>
              {time}
            </p>
          </div>
        </div>
        <a href="#contact" className="btn">
          Let’s Edit!
        </a>
      </div>
    </div>
  )
}
