import {Minus, Plus} from "../Icons/Icons";
import {useState} from "react";

export default function Accordion({children, title}) {
  const [active, setActive] = useState(false);
  const handleOnClick = () => setActive(prevState => !prevState);

  return(
    <div className={active ? "accordion active" : "accordion"} onClick={handleOnClick}>
      <div className="accordion__head">
        {title}
        <div className="open-close">
          <Plus/>
          <Minus/>
        </div>
      </div>
      <div className="accordion__content">
        {children}
      </div>
    </div>
  )
}