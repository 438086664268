import {Line} from "../../../../../Components/Icons/Icons";

export default function PricingItem({status, title, type, price, newPrice, frequency, info, time}) {
  return (
    <div className="pricing__item">
      <div className="pricing__head">
        <div className="pricing__status">
          {status}
        </div>
        <h2 className="title">
          {title}
        </h2>
        <div className="pricing__type">
          {type}
        </div>
      </div>
      <div className="pricing__body">
        {/*<div className="pricing__price pricing__price_new">*/}
        {/*  <span>$</span>*/}
        {/*  {newPrice}*/}
        {/*</div>*/}
        {/*<div className="pricing__price pricing__price_old">*/}
        {/*  <span>$</span>*/}
        {/*  {price}*/}
        {/*  <Line/>*/}
        {/*</div>*/}
        {/*<div className="pricing__frequency">*/}
        {/*  {frequency}*/}
        {/*</div>*/}
        <div className="text">
          <div className="pricing__info">
            <span>
              what you get
            </span>
            <p>
              {info}
            </p>
          </div>
          <div className="pricing__time">
            <span>
              turnaround time
            </span>
            <p>
              {time}
            </p>
          </div>
        </div>
        <a href="#contact" className="btn">
          Let’s Edit!
        </a>
      </div>
    </div>
  )
}