export default function UsersTableHead() {
  return(
    <div className="users__head">
      <div className="col">
        User
      </div>
      <div className="col">
        Email
      </div>
      <div className="col">
        Password
      </div>
    </div>
  )
}