import Hero from "./Sections/Hero/Hero";
import HowItWork from "./Sections/HowItWork/HowItWork";
import Pricing from "./Sections/Pricing/Pricing";
import EzEdit from "./Sections/EzEdit/EzEdit";
import Portfolio from "./Sections/Portfolio/Portfolio";
import Faq from "./Sections/Faq/Faq";
import Reviews from "./Sections/Reviews/Reviews";
import AboutUs from "./Sections/AboutUs/AboutUs";
import Contact from "./Sections/Contact/Contact";
import Trial from "./Sections/Trial/Trial";
import {CalendlyWidget} from "./Sections/CalendlyWidget/CalendlyWidget";
import {Klaviyo} from "./Sections/Klaviyo/Klaviyo";

export default function Home(){
  return (
    <main>
      <Hero/>
      <HowItWork/>
      <CalendlyWidget/>
      <Pricing/>
      <Klaviyo/>
      <Trial/>
      <EzEdit/>
      <Portfolio/>
      <Faq/>
      <Reviews/>
      <AboutUs/>
      <Contact/>
    </main>
  )
}