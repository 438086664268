import './Contact.scss'
import {PopupButton} from "react-calendly";

export default function Contact() {
  return (
    <section className="contact" >
      <div className="wrap">
        <h2 className="title">
          Let’s Talk!
        </h2>
        <div className="btns">
        <PopupButton
          className='btn btn_white'
          url="https://calendly.com/d/z6p-thn-w6b/30min%27"
          rootElement={document.getElementById("root")}
          text="Book a Call"
        />
          <a href="mailto:info@ezedit.com" className="btn btn_white">
            Email Us
          </a>
        </div>
      </div>
    </section>
  )
}