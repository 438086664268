import './LogIn.scss'
import loginImg from './Content/login-bg.jpg'
import letsGo from './Content/LetsGo.png'
import {Link, useNavigate} from "react-router-dom";
import {LogoSmall} from "../../Components/Icons/Icons";
import {useForm} from "react-hook-form";
import {auth} from "../../API/LogIn/LogIn";
import {useEffect, useState} from "react";
import tokenServices from "../../services/tokenServices";

export default function LogIn() {
  const navigate = useNavigate();
  const [resError, setResError] = useState('');
  const {register, handleSubmit, formState: {errors}} = useForm();
  const onSubmit = data => {
    auth(data.email, data.password).then(res => {
      if (res.status.toString()[0] === '4') {
        setResError(res.data.message);
        setTimeout(() => setResError(''), 3000)
      }
      if (res.status.toString()[0] === '2') {
        navigate("/user-dashboard");
      }
    })
  };

  useEffect(() => {
    if (tokenServices.getUser()) {
      navigate("/");
    }
  })

  return (
    <section className="login">
      <div className="wrap">
        <div className="left">
          <img src={loginImg} className="login__bg"/>
          <div className="login__title">
            Ready to edit your videos with no limits?
          </div>
          <img src={letsGo} className="lets-go"/>
        </div>
        <div className="right">
          <Link to="/" className="logo">
            <LogoSmall/>
          </Link>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form__title">
              Log in
            </div>
            <label htmlFor="email">
              <input
                id='email'
                type="email"
                placeholder='Email'
                {...register("email",
                  {
                    required: true,
                    pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
                  })
                }
              />
              {
                errors.email?.type === 'pattern' &&
                <p role="alert" className='error'>
                  Enter the correct email address
                </p>
              }
            </label>
            <label htmlFor="password">
              <input
                id='password'
                type="password"
                placeholder='Password'
                {...register("password",
                  {
                    required: true,
                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ -/:-@\[-`{-~]).{8,64}$/
                  })
                }
              />
              {
                errors.password?.type === 'pattern' &&
                <p role="alert" className='error'>The password must contain characters numbers uppercase and lowercase
                  letters the minimum length is 8 characters
                </p>
              }
            </label>
            <input type="submit" className='btn' value='Get Started'/>
            {
              resError &&
              <p role="alert" className='error tac'>
                {resError}
              </p>
            }
          </form>
        </div>
      </div>
    </section>
  )
}