import {useEffect, useState} from "react";
import {Close} from "../Icons/Icons";

import('./Modal.scss')

export default function Modal({children, hide}) {
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsShow(true));
  }, []);

  const handleOnClick = ()=>{
    setIsShow(false);
    setTimeout(()=>hide(false), 305)
  }

  return (
    <div className={isShow ? 'modal show' : 'modal'}>
      <div className="close" onClick={() => handleOnClick()}>
        <Close/>
      </div>
      <div className="modal__bg" onClick={() => handleOnClick()}></div>
      <div className="content">
        {children}
      </div>
    </div>
  )
}