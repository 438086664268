import {Users} from "../../../Components/Icons/Icons";

export default function MenuItems() {
  return (
    <div className="menu__items">
      <div className="menu__item active">
        <Users/> Users
      </div>
    </div>
  )
}