import {Circles} from "../../../../Components/Icons/Icons";

export const AboutDate = () => (
  <div className="date">
    <div className="date__item">
      2010
    </div>
    <Circles/>
    <div className="date__item">
      2023
    </div>
  </div>
)