import Modal from "../../../Components/Modal/Modal";
import {useForm} from "react-hook-form";
import {registerUser} from "../../../API/Register/Register";
import {useState} from "react";

export default function UserAdd({showUserAdd, setShowUserAdd}) {
  const [message, setMessage] = useState({message: '', type: 'successes'})
  const {register, handleSubmit, reset} = useForm();
  const onSubmit = data => {
    const {name, email, password, iframe} = data;
    registerUser(name, email, password, iframe).then(res => {
      if (res.status.toString()[0] === '2') {
        setMessage({message: 'A new user has been registered', type: 'successes'});
        reset({});
      }
      if (res.status.toString()[0] === '4') {
        // do something
        setMessage({message: res.data.message, type: 'error'});
      }
    });
  };

  if (showUserAdd) {
    return (
      <Modal hide={setShowUserAdd}>
        <form className='user-add' onSubmit={handleSubmit(onSubmit)}>
          <div className="user-add__title">
            Invite collaborators
          </div>
          <label htmlFor="name">
            <span>
              Name Surename
            </span>
            <input
              type="text"
              id='name'
              {...register("name",
                {
                  required: true,
                })
              }
            />
          </label>
          <label htmlFor="email">
            <span>
              Email
            </span>
            <input
              type="text"
              id='email'
              {...register("email",
                {
                  required: true,
                  pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
                })
              }
            />
          </label>
          <label htmlFor="password">
            <span>
              Password
            </span>
            <input
              type="text"
              id='password'
              {...register("password",
                {
                  required: true,
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ -/:-@\[-`{-~]).{8,64}$/
                })
              }
            />
          </label>
          <label htmlFor="monday">
            <span>
              iframe link
            </span>
            <input
              type="text"
              id='monday'
              {...register("iframe",
                {
                  required: true,
                })
              }
            />
          </label>
          <div className="btns">
            <div className="btn btn_white" onClick={() => setShowUserAdd(false)}>
              Cancel
            </div>
            <input
              type="submit"
              className="btn btn_blue"
              value='Add User'
            />
          </div>
          {
            message.message &&
            <p role="alert" className={`message ${message.type}`}>
              {message.message}
            </p>
          }
        </form>
      </Modal>
    )
  }
}