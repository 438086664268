import {HowItWorkData} from "./HowItWorkData";
import ZigzagItem from "../../../../Components/Zigzag/ZigzagItem";
import Zigzag from "../../../../Components/Zigzag/Zigzag";

export const HowItWorkContent = () => (
  <Zigzag>
    {HowItWorkData.map((item, index) => (
      <ZigzagItem
        image={item.img}
        key={item.title + index}
      >
        <div className="content">
          <h2 className="title">
            {item.title}
          </h2>
          <div className="text">
            {item.text}
          </div>
        </div>
      </ZigzagItem>
    ))}
  </Zigzag>
)