import './Admin.scss';
import AdminContent from "./AdminContent";
import Menu from "./Menu/Menu";
import {useEffect, useState} from "react";
import jwt_decode from "jwt-decode";
import tokenServices from "../../services/tokenServices";
import {useNavigate} from "react-router-dom";

export default function Admin() {
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (tokenServices.getUser()) {
      if (jwt_decode(tokenServices.getUser().accessToken).role === "ADMIN") {
        setIsAdmin(true);
      }
    } else {
      navigate('/');
    }
  })

  if (isAdmin) {
    return (
      <section className="admin">
        <Menu/>
        <AdminContent/>
      </section>
    )
  }

}