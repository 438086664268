import './AboutUs.scss'
import {AboutDate} from "./AboutDate";
import AboutItems from "./AboutItems";

export default function AboutUs() {
  return (
    <section className="about-us">
      <div className="wrap">
        <h2 className="title">
          About Us
        </h2>
        <div className="content">
          <AboutDate/>
          <AboutItems/>
        </div>
      </div>
    </section>
  )
}