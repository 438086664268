import "./Portfolio.scss"
import PortfolioItems from "./PortfolioItems";

export default function Portfolio() {
  return (
    <section className='portfolio' id='portfolio'>
      <div className="wrap">
        <h2 className="title">
          Case study videos
        </h2>
        <PortfolioItems/>
      </div>
    </section>
  )
}