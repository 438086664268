import './Header.scss'
import {Logo} from "../Icons/Icons";
import HeaderMenu from "./HeaderMenu";
import {useEffect, useState} from "react";
import {useLocationMatch} from "../../hooks/useLocationMatch";
import {useLocation} from "react-router-dom";
import tokenServices from "../../services/tokenServices";
import api from "../../API/Services/api";

export default function Header() {
  const [active, setActive] = useState(false);
  const location = useLocation();
  const hideHeader = useLocationMatch(location.pathname, ['login', 'admin', 'opt-in', 'coupon', 'thanks']);
  const getUserData = () => api.get('/users');

  useEffect(() => {
    if (tokenServices.getUser()) {
      getUserData().then(res => res).catch(e => e);
    }
  }, [])

  if (!hideHeader) {
    return (
      <header className={active ? 'header active' : 'header'}>
        <div className="wrap">
          <a href="/" className="logo">
            <Logo/>
          </a>
          <HeaderMenu setActive={setActive}/>
        </div>
      </header>
    )
  }
}