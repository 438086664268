import './EzEdit.scss'
import EzEditBg from "./EzEditBg";
import Tile from "./Tile/Tile";

export default function EzEdit() {
  return (
    <section className="ezedit" id='how-it-works'>
      <EzEditBg/>
      <div className="wrap">
        <h2 className="title">
          How To <span>ezEdit?</span>
        </h2>
        <Tile/>
      </div>
    </section>
  )
}