import './Klaviyo.scss'

export const Klaviyo = () => (
  <div className='klaviyo'>
    <div className="wrap">
      <h2 className="title">
        Enter your email below to find out about our special offers and package pricing
      </h2>
      <div className="klaviyo-form-V3fmvr"></div>
    </div>
  </div>
)