import {UsersHeader} from "./UsersHeader";
import UsersTable from "./UsersTable";
import './Users.scss'
import UserAdd from "./UserAdd";
import {useState} from "react";

export default function Users() {
  const [showUserAdd, setShowUserAdd] = useState(false);

  return (
    <div className="users-view">
      <UsersHeader setShowUserAdd={setShowUserAdd}/>
      <UsersTable/>
      <UserAdd
        showUserAdd={showUserAdd}
        setShowUserAdd={setShowUserAdd}
      />
    </div>
  )
}