import './OptIn.scss'
import {useForm} from "react-hook-form";
import {Logo} from "../../Components/Icons/Icons";
import {klavioAddUser, klavioAddUserToList} from "../../API/Opt-in/Opt-in";
import {useLocationMatch} from "../../hooks/useLocationMatch";
import {useLocation, useNavigate} from "react-router-dom";

export const OptIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isCouponPage = useLocationMatch(location.pathname, ['coupon']);
  const {register, handleSubmit, formState: {errors}} = useForm();
  const onSubmit = data => {
    klavioAddUser(data.name, data.email).then((res) => {
      const listId = isCouponPage ? 'ShfTXF' : 'WvAPyJ'
      klavioAddUserToList(listId, data.email).then(res => {
        if (res.status.toString()[0] === '2') {
          isCouponPage ?
            navigate('/thanks?after=coupon')
            :
            navigate('/thanks?after=opt-in')
        }
      })
    });
  }

  return (
    <section className='opt-in'>
      <Logo/>

      <div className="wrap">
        <h1 className="title">
          {isCouponPage ?
            'Enter Your Information Below For A $200 Coupon Code' :
            'Download our 10 best tips for going viral.'
          }
        </h1>
        <div className="text">
          {isCouponPage ?
            'Get $200 off your first month when you sign up for ezEdit, ezEdit provides unlimited video subscription packages.' :
            'We\'ve worked with a ton of content creators and we\'ve noticed a few things!'
          }
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="name">
            <span>
              Name
            </span>
            <input
              type="text"
              id='name'
              {...register("name",
                {
                  required: true,
                })
              }
            />
          </label>
          <label htmlFor="email">
            <span>
              Email
            </span>
            <input
              id='email'
              type="email"
              {...register("email",
                {
                  required: true,
                  pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
                })
              }
            />
            {
              errors.email?.type === 'pattern' &&
              <p role="alert" className='error'>
                Enter the correct email address
              </p>
            }
          </label>
          <input
            type="submit"
            className="btn"
            value='Get Access Now'
          />
        </form>
        <div className="advantages">
          <h2 className="title">
            What You’ll Get:
          </h2>
          <div className="advantages__item">
            {isCouponPage ?
              'Get $200 OFF to use towards your first month’s subscription. The two weeks trial period is valid with the coupon code.' :
              'Our top ten tips for going viral and a comprehensive setup guide for your home studio'
            }
          </div>
        </div>
      </div>
    </section>
  )
}