export default function User({user}){
  return(
    <div className="user row">
      <div className="col">
        {user.name}
      </div>
      <div className="col">
        {user.email}
      </div>
      <div className="col">
        {user.password}
      </div>
    </div>
  )
}