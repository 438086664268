import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import {Swiper, SwiperSlide} from "swiper/react";
import {Grid, Navigation} from "swiper";
import {PortfolioData} from "./PortfolioData";
import YouTube from "react-youtube";

export default function PortfolioItems() {

  if(PortfolioData.length < 6) {
    return (
        <div className="portfolio__items">
          {PortfolioData.map((item, index) => (
            <div className='portfolio__item' key={`portfolioItems${index}`}>
              <YouTube
                videoId={item.videoId}
              />
            </div>
          ))}
        </div>
    )
  }

  return (
    <Swiper
      slidesPerView={3}
      grid={{
        rows: 2,
      }}
      navigation={true}
      spaceBetween={24}
      modules={[Grid, Navigation]}
      breakpoints={{
        320: {
          slidesPerView: 1,
          grid: {rows: 1},
          spaceBetween: 16
        },
        768: {
          slidesPerView: 3,
          grid: {rows: 2},
          spaceBetween: 24
        },
      }}
      className="portfolio-swiper"
    >
      {PortfolioData.map((item, index) => (
        <SwiperSlide key={`portfolioSlider${index}`}>
          <YouTube
            videoId={item.videoId}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}