import './HowItWork.scss'
import {HowItWorkContent} from "./HowItWorkContent";

export default function HowItWork() {
  return (
    <section className='how-it-work'>
      <div className="wrap">
        <HowItWorkContent/>
      </div>
    </section>
  )
}