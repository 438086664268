import HowItWorkImage1 from './Content/howitwork-img1.png'
import HowItWorkImage2 from './Content/howitwork-img2.png'
import HowItWorkImage3 from './Content/howitwork-img3.png'
import HowItWorkImage4 from './Content/howitwork-img4.png'
import HowItWorkImage5 from './Content/howitwork-img5.png'

export const HowItWorkData = [
  {
    title: 'Why do you need ezEdit?',
    text: 'You’ve got a brand to build and a world to win. But there’s a lot of videos out there, and to stand out, you need to post a lot… every day if you can. Focus on quality and consistency and leave the editing to us. Your talent, our time.',
    img: HowItWorkImage1,
  },
  {
    title: 'How much, how fast?',
    text: 'Upload your footage and download your finished video, as fast as the next day and often sooner. We’ll cut as much as you can shoot. See prices and turnaround times.',
    img: HowItWorkImage2,
  },
  {
    title: 'Don’t need unlimited?',
    text: 'All good. Pay as you go for one video at a time. We’re here whenever you need us.',
    img: HowItWorkImage3,
  },
  {
    title: 'Who edits your video?',
    text: 'Our experienced team of editors are based in Canada. Between them they’ve cut thousands of hours of online videos, commercials, and shows. You’ll have your own editor. They’ll learn your style and you can share your ideas with them.',
    img: HowItWorkImage4,
  },
  {
    title: 'What formats?',
    text: 'All of them, tall, wide, and square. We output for TikTok, Instagram, Facebook, YouTube, Vimeo, and more.',
    img: HowItWorkImage5,
  }
]