import {Link} from "react-router-dom";

export const FooterBottom = () => (
  <div className="footer__bot">
    <div className="copyright">
      ©2023 ezedit. All rights reserved.
    </div>
    <ul>
      <li>
        <Link to='/terms-and-conditions'>
          Terms
        </Link>
      </li>
    </ul>
  </div>
)