import './Pricing.scss';
import PricingItems from "./PricingItems";

export default function Pricing() {
  return (
    <section className="pricing" id='pricing'>
      <div className="wrap">
        <h2 className="title">
          Pricing
        </h2>
        <PricingItems/>
      </div>
    </section>
  )
}