import {Swiper, SwiperSlide} from "swiper/react";
import {EffectFade, Navigation, Thumbs} from "swiper";
import {ReviewsData} from "./ReviewsData";
import {useState} from "react";
import {PlayVideo} from "../../../../Components/Icons/Icons";
import playVideo from "./Content/Play-Video.png"

export default function ReviewsSlider({setShowModal, setVideoId}) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const handleClick = (videoId) => {
    setShowModal(true);
    setVideoId(videoId);
  }

  return (
    <>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={100}
        allowTouchMove={false}
        loop={true}
        effect={"fade"}
        modules={[Navigation, Thumbs, EffectFade]}
        className="reviews-slider"
      >
        {ReviewsData.map((item, index) => (
          <SwiperSlide key={`sliderImageSlide${index}`}>
            <img className='ava' src={item.img}/>
            {
              item.videoId ?
                <>
                  <img className='play-video-img' src={playVideo} alt="play video"/>
                  <div className="play-icon" onClick={() => handleClick(item.videoId)}>
                    <PlayVideo/>
                  </div>
                </>
                :
                <></>
            }
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        thumbs={{swiper: thumbsSwiper}}
        spaceBetween={10}
        slidesPerView={1}
        loop={true}
        navigation={true}
        watchSlidesProgress={true}
        effect={"fade"}
        modules={[Navigation, Thumbs, EffectFade]}
        className="reviews-slider-content"
      >
        {ReviewsData.map((item, index) => (
          <SwiperSlide key={`sliderSlide${index}`}>
            <div className="reviews__head">
              <a href={`https://www.instagram.com/${item.inst}`} target='_blank'>@{item.inst}</a>
            </div>
            <div className="text">
              {item.desc}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}