import './App.scss';
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./Pages/Home/Home";
import LogIn from "./Pages/LogIn/LogIn";
import Admin from "./Pages/Admin/Admin";
import UserDashboard from "./Pages/UserDashboard/UserDashboard";
import TermsAndConditions from "./Pages/TermsAndConditions/TermsAndConditions";
import {OptIn} from "./Pages/OptIn/OptIn";
import Thanks from "./Pages/Thanks/Thanks";

function App() {
  return (
    <BrowserRouter>
    <div className="App">
      <Header/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/login" element={<LogIn/>}/>
        <Route path="/admin" element={<Admin/>}/>
        <Route path="/user-dashboard" element={<UserDashboard/>}/>
        <Route path="/terms-and-conditions" element={<TermsAndConditions/>}/>
        <Route path="/opt-in" element={<OptIn/>}/>
        <Route path="/coupon" element={<OptIn/>}/>
        <Route path="/thanks" element={<Thanks/>}/>
      </Routes>
      <Footer/>
    </div>
    </BrowserRouter>
  );
}

export default App;
