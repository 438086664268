import chefmichaelImage from './Content/@chefmichael.png'
import kennykooooImage from './Content/@KennyKoooo.png'
import timeofdreImage from './Content/@TimeofDre.png'
import josephImage from './Content/joseph.png'
import sashasImage from './Content/sashas.png'
import ssspicymayoImage from './Content/ssspicymayo.png'

export const ReviewsData = [
  {
    inst: 'chefmichael',
    desc:
      <>
        <strong> EzEdit is a GAME CHANGER for me!</strong> I was constantly behind on editing. EZEdit came in and it
        allowed me to focus on my filming. Like magic, I get back a stunning video. <strong>Not only are they quick, but
        they are GOOD!</strong> The editing is truly top notch, and somehow they always manage to capture my
        personality!
        This has helped my business and allowed me to become a better, more effective creator. Thank you, EZEdit!
      </>,
    img: chefmichaelImage,
    videoId: 'tc-9kkJxfaI'
  },
  {
    inst: 'KennyKoooo',
    desc:
      <>
        <strong>The most pleasurable video editing experience!</strong> Most companies rushed the process, were hard to
        get hold
        of, and fell far below my expectations. With EzEdit my eyes were opened. Videos were done in remarkably quick
        fashion, and the edits surpassed my skills and expectations. The turnaround time blew me away. <strong>There's
        no
        other company I'd trust with creating my content.</strong>
      </>,
    img: kennykooooImage,
    videoId: 'mVaouQYKu1o'
  },
  {
    inst: 'TimeofDre',
    desc:
      <>
        As a busy entrepreneur, sitting around and editing all day isn't in my best interest. Working with ezEdit, I was
        able to drop my content in a folder and *poof* it would end up back in my inbox a few days later all ready to
        go. Using the unlimited package, I was able to get dozens of videos edited and posted across all of my social
        media platforms in truly the easiest way possible. <strong> The process was streamlined, simple, and
        FUN!</strong> I easily saved
        over 15 hours this month from being hunched over my phone.
      </>,
    img: timeofdreImage,
    videoId: 'elhjkL6pkbY'
  },
  {
    inst: 'Joseph_Video',
    desc:
      <>
        I am a content creator, but I also have 20 years of experience of video editing. Because of the experience I
        have, I can't just hire any editor. I have pretty high standards for my videos. The problem I'm having is there
        are too many videos to edit. So I put out a call to editors and one stood out, EZEdit. And my goodness, did they
        ever deliver. <strong>The first edit I got back. Wow. I was pretty impressed.</strong> And if there's anything I
        wanted changed,
        I just gave notes and they nailed it. So rest assured, if you are not a professional video editor, these guys
        are the real deal. EZEdit, easy decision.
      </>,
    img: josephImage,
    videoId: '6ZelDe9Ecl8'
  },
  {
    inst: 'sashas_newplayhaus',
    desc:
      <>
        Trying to be a content creator and having a full-time job is probably the hardest thing in the world, especially
        when you're trying to get out content in a timely manner and staying within brand, and just honestly editing all
        of your content. I decided to outsource my video editing to a company called EZedit, and it has <strong>literally
        been
        the best thing in the world. They saved me, honestly, like 15 to 20 hours a week worth of editing.</strong> Now,
        that's a
        lot, especially when I work a full-time job. They know my branding, they know exactly what I want. Their
        turnaround time is literally so fast, about one to two days, they're perfect. They know exactly what I need from
        my videos without me really even telling them because they've learned my brand. Make sure you guys sign up if
        you're a content creator and need that little bit of extra time.
      </>,
    img: ssspicymayoImage,
    videoId: '5UfQON0LQqg'
  },
  {
    inst: 'ssspicymayo',
    desc:
      <>
        Trying to be a content creator and working a full-time job has been so hard. <strong>I'm so thankful that I
        found
        EZEdit. They know my style. They know my branding. I submit my videos. Turnaround time is super
        quick.</strong> One to
        two days. They save me countless hours during the week. They're so amazing. Go ahead and sign up. <sstrong>You
        won't
        regret it.</sstrong>
      </>,
    img: sashasImage,
    videoId: 'nlRGRNAnBM8'
  },
]