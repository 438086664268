import './Footer.scss';
import {useLocation} from "react-router-dom";
import {useLocationMatch} from "../../hooks/useLocationMatch";
import {FooterBottom} from "./FooterBottom";
import {FooterTop} from "./FooterTop";

export default function Footer() {
  const location = useLocation();
  const hideFooter = useLocationMatch(location.pathname, ['login', 'admin', 'opt-in', 'coupon', 'thanks']);

  if (!hideFooter) {
    return (
      <footer className="footer">
        <div className="wrap">
          <FooterTop/>
          <FooterBottom/>
        </div>
      </footer>
    )
  }
}