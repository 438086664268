import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import 'swiper/css/effect-fade';
import './Reviews.scss'
import ReviewsSlider from "./ReviewsSlider";
import Modal from "../../../../Components/Modal/Modal";
import {useState} from "react";
import YouTube from "react-youtube";


export default function Reviews() {
  const [showModal, setShowModal] = useState(false);
  const [videoId, setVideoId] = useState('');

  return (
    <section className="reviews">
      <div className="wrap">
        <ReviewsSlider
          setShowModal={setShowModal}
          setVideoId={setVideoId}
        />
      </div>
      {showModal ?
        <Modal hide={setShowModal}>
          <YouTube
            videoId={videoId}
          />
        </Modal>
        :
        <></>
      }
    </section>
  )
}