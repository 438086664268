import TokenService from "../../services/tokenServices";
import api from "../Services/api";

export const auth = (email, password) => api.post(`/auth/signin`, {email, password})
  .then((response) => {
    if (response.data.accessToken) {
      TokenService.setUser(response.data);
    }

    return response;
  })
  .catch(err => {
    return err.response;
  });