export const PortfolioData = [
  {
    videoId: 'CZU0QB-V7ZM',
  },
  {
    videoId: 'nlU8_kzJjt0',
  },
  {
    videoId: 'wwTqWseTxiY',
  },
  {
    videoId: 'iAObZ6wwyx4',
  },
  {
    videoId: 'LypXawvHvZg',
  },
  {
    videoId: 'In_3WYzK_8U',
  },
]