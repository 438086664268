import {Export} from "../../../Components/Icons/Icons";

export const UsersHeader = ({setShowUserAdd}) => (
  <div className="users-view__header">
    <div className="title">
      User Data
    </div>
    <div className="btn btn_blue" onClick={() => setShowUserAdd(true)}>
      Add New User
    </div>
  </div>
)