import './Hero.scss'
import heroImage from './Content/hero-image.jpg'
import heroImageMobile from './Content/hero-image-mobile.jpg'
import promoVideo from './Content/promo.mp4'
import heroPromo from './Content/No limits.png'
import {HeroContent, HeroTitle} from "./HeroData";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import {Video} from "../../../../Components/Video/Video";

export default function Hero() {
  const {width} = useWindowDimensions();

  return (
    <section className='hero'>
      <div className="wrap">
        <div className="content">
          <h1 className="title">
            <HeroTitle/>
          </h1>
          <img className='promo-image' src={heroPromo} alt="No limits"/>
          <div className="text">
            <HeroContent/>
          </div>
          <div className="btns">
            <a href="#trial" className="btn">
              Try For Free
            </a>
            <a href="#pricing" className="btn btn_pupil">
              <span className="btn_pupil__white">
                What It Costs
              </span>
            </a>
          </div>
          <div className="text">
            Try us for 14 days, money back guarantee. No strings attached!
          </div>
        </div>
        <Video videoUrl={promoVideo}/>
        <img src={width > 1024 ? heroImage : heroImageMobile} className='hero-image' alt="Content Creators"/>
      </div>
    </section>
  )
}