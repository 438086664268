import useWindowDimensions from "../../hooks/useWindowDimensions";
import {Burger, Close} from "../Icons/Icons";
import {Menu} from "../Menu/Menu";
import tokenServices from "../../services/tokenServices";
import {Link} from "react-router-dom";

export default function HeaderMenu({setActive}) {
  const {width} = useWindowDimensions();
  const isUserLogin = tokenServices.getUser();

  if (width > 1024) {
    return (
      <>
        <Menu/>
        {isUserLogin ?
          <Link to="/user-dashboard" className="btn">
            Dashboard
          </Link>
          :
          <Link to="/login" className="btn">
            Client Log In
          </Link>
        }
      </>
    )
  }

  return (
    <>
      <div className="menu-open" onClick={() => setActive(prev => !prev)}>
        <Burger/>
        <Close/>
      </div>
      <div className="menu">
        <Menu/>
        {isUserLogin ?
          <Link to="/user-dashboard" className="btn">
            Dashboard
          </Link>
          :
          <Link to="/login" className="btn">
            Client Log In
          </Link>
        }
      </div>
    </>
  )
}