import api from "../Services/api";

export const klavioAddUser = (firstName, email) =>
  api.post(
    `/klavio/add-klavio-account`,
    {
      firstName,
      lastName: '',
      email,
      organization: '',
      title: '',
      image: '',
      location: {
        address1: '',
        address2: '',
        city: '',
        country: '',
        region: '',
        zip: '',
        timezone: '',
      },
    }
  ).then(res => {
    return res;
  }).catch(err => {
    return err.response;
  });


export const klavioAddUserToList = (listId, email) =>
  api.post(
    `/klavio/add-klavio-account-to-list`,
    {
      listId,
      email,
    }
  ).then(res => {
    return res;
  }).catch(err => {
    return err.response;
  });
