import api from "../Services/api";
import TokenService from "../../services/tokenServices";

export const registerUser = (name, email, password, iframe) =>
  api.post(
    `/users/register`,
    {
      name,
      email,
      password,
      iframe
    }
  ).then(res => {
    if (res.data.token) {
      TokenService.setUser(res.data);
    }
    return res;
  }).catch(err => {
    return err.response;
  });