import './Thanks.scss'
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {PopupButton} from "react-calendly";
import {useEffect} from "react";

export default function Thanks() {
  const [searchParams, setSearchParams] = useSearchParams();
  const afterCoupon = searchParams.get("after") === 'coupon';
  const navigate = useNavigate();

  useEffect(() => {
    if (!afterCoupon && searchParams.get("after") !== 'opt-in') {
      navigate('/');
    }
  }, [])

  return (
    <section className='thanks'>
      <div className="round"></div>
      <div className="bg"></div>
      <div className="wrap">
        <h1 className="title">
          {afterCoupon ?
            <>Thank you! <br/> Check your inbox for the <br/> $200 discount code!</>
            :
            <>Thank you! <br/> Check your inbox for our top tips for creating viral video content!</>
          }
        </h1>
        <p>
          {afterCoupon ?
            <>Book a call today to learn more about which unlimited video package is best for you.
              Start saving time creating your videos so you can focus on doing other things!</>
            :
            <>Want to save even more time creating your videos so you can focus on doing other things?</>
          }
        </p>
        <strong>
          {afterCoupon ?
            <>Let’s chat! Click the link below to book a call.</>
            :
            <>Ezedit offers unlimited video editing services. Book a call below to learn more!</>
          }
        </strong>
        <div className="btns">
          <Link to='/' className="btn btn_pupil">
            <span className="btn_pupil__white">
               Go to Home Page
            </span>
          </Link>
          <PopupButton
            className='btn'
            url="https://calendly.com/d/z6p-thn-w6b/30min%27"
            rootElement={document.getElementById("root")}
            text="Book a Call"
          />
        </div>
        <p>
          Try us for 14 days, money back guarantee. No strings attached!
        </p>
      </div>
    </section>
  )
}