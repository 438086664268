import {Export, Medal, Message, Scissor, Video} from "../../../../Components/Icons/Icons";

export const EzEditData = [
  {
    icon: <Export/>,
    text: 'Upload your footage'
  },
  {
    icon: <Message/>,
    text: 'Tell us what you want'
  },
  {
    icon: <Video/>,
    text: 'Review your video– as fast as next day'
  },
  {
    icon: <Scissor/>,
    text: 'Need changes? Let’s make it how you want it!'
  },
  {
    icon: <Medal/>,
    text: 'When you’re happy, let’s start another!'
  },
]