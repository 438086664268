import {LogoFull} from "../Icons/Icons";
import {Menu} from "../Menu/Menu";

export const FooterTop = () => (

  <div className="footer__top">
    <a href="/" className="logo">
      <LogoFull/>
    </a>
    <Menu/>
    <div className="instagram">
      Follow us on Instagram
      <a href="https://www.instagram.com/ezedit_videos/" target='_blank'>
        @ezedit_videos
      </a>
    </div>
  </div>
)