import {Link} from "react-router-dom";
import {Logo} from "../../../Components/Icons/Icons";
import MenuItems from "./MenuItems";

export default function Menu () {
  return (
    <div className="menu">
      <Link className="logo" to="/">
        <Logo/>
      </Link>
      <MenuItems/>
    </div>
  )
}