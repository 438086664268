import './Faq.scss'
import AccordionContainer from "../../../../Components/Accordion/AccordionContainer";
import Accordion from "../../../../Components/Accordion/Accordion";
import {FaqData} from "./FaqData";
import {useState} from "react";

export default function Faq() {
  const [isShowAll, setShowAll] = useState(false);
  const data = isShowAll ? FaqData : FaqData.slice(0, 5);

  return (
    <section className="faq" id='faq'>
      <div className="wrap">
        <h2 className="title">
          Frequently Asked Questions
        </h2>
        <AccordionContainer>
          {data.map((item, index) => (
            <Accordion title={item.title} key={item.title + index}>
              {item.content()}
            </Accordion>
          ))}
        </AccordionContainer>
        <div className="btn btn_pupil">
          <div className="btn_pupil__white" onClick={() => setShowAll((prev) => !prev)}>
            {!isShowAll ? 'See All Questions' : 'Hide All Questions'}
          </div>
        </div>
      </div>
    </section>
  )
}