import {InlineWidget} from "react-calendly";
import './CalendlyWidget.scss'

export const CalendlyWidget = () => (
<section id='contact'>
  <h2 className="title">
    Book an Intro Call Now
  </h2>
  <InlineWidget url="https://calendly.com/ezedit/15min" />
</section>
)