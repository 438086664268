import {PricingData} from "./PricingData";
import PricingItem from "./PricingItem/PricingItem";
import PricingItemVariable from "./PricingItem/PricingItemVariable";

export default function PricingItems() {
  return (
    <div className="pricing__items">
      {PricingData.map((item, index) => (
        item.variable ?
          <PricingItemVariable
            title={item.title}
            variations={item.variations}
            frequency={item.frequency}
            info={item.info}
            time={item.time}
            key={item.title + index}
          />
          :
          <PricingItem
            status={item.status}
            title={item.title}
            type={item.type}
            price={item.price}
            newPrice={item.newPrice}
            frequency={item.frequency}
            info={item.info}
            time={item.time}
            key={item.title + index}
          />
      ))}
    </div>
  )
}