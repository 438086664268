export const PricingData = [
  {
    variable: false,
    variations: [],
    status: 'Unlimited',
    title: 'REELS',
    type: 'package',
    price: '995',
    newPrice: '597',
    frequency: 'monthly',
    info: <>All the shorts you can shoot.<br/> We cut your footage <br/> to under 60 seconds.</>,
    time: <>Up to 1-2 business days</>,
  },
  {
    variable: false,
    variations: [],
    status: 'Unlimited',
    title: 'STARTER',
    type: 'package',
    price: '1545',
    newPrice: '927',
    frequency: 'monthly',
    info: <>This way to stardom. Get all your short and long videos at an affordable price.</>,
    time: <>Short videos (under 60 seconds): up to 1-2 business days <br/> Long videos (over 60 seconds): up to 4-5 business days</>,
  },
  {
    variable: false,
    variations: [],
    status: 'Unlimited',
    title: 'PRO',
    type: 'package',
    price: '2950',
    newPrice: '1770',
    frequency: 'monthly',
    info: <>You’re a pro.<br/> Get all the editing you need.<br/> Fast.</>,
    time: <>Short form video (under 60 seconds): 1-2 business days<br/>Long form video (above 60 seconds): 2-3 business days</>,
  },
  {
    variable: true,
    variations: [
      {
        name: 'Under 60 sec',
        price: '79'
      },
      {
        name: 'Above 60 sec',
        price: '379'
      }
    ],
    status: 'limitless',
    title: 'PAY-AS YOU-GO',
    type: 'package',
    price: '1195',
    frequency: 'video',
    info: <>Need just a short form or a long form video edited? Tackle one project at a time.</>,
    time: <>Up to 1-2 business days</>,
  },
]