export const FaqData = [
  {
    title: 'How does ezEdit work?',
    content: () => (
      <>
        <p>
          Upload your footage and fill out our ezForm where you’ll tell us about your project. Your editor will get
          cutting. Expect a finished video as fast as next day <a href="#pricing">(see turnaround times)</a>.
        </p>
        <p>
          You like it? Post it. Need changes? Tell us. You get as many revisions as you want. We’re not happy til you’re
          happy. You can upload your footage to WeTransfer, Dropbox, or Google Drive. If you have a different service
          that
          you prefer to use, let us know.
        </p>
      </>
    ),
  },
  {
    title: 'What if I want to cancel?',
    content: () => (
      <>
        <p>
          Cancel any time! Just tell us 14 days before your monthly subscription is up.

        </p>
        <p>
          Contact us with your cancellation notice.
        </p>
      </>
    ),
  },
  {
    title: 'Are the unlimited packages really unlimited?',
    content: () => (
      <>
        <p>
          Upload your footage and fill out our ezForm where you’ll tell us about your project. Your editor will get
          cutting. Expect a finished video as fast as next day <a href="#pricing">(see turnaround times)</a>.
        </p>
        <p>
          You like it? Post it. Need changes? Tell us. You get as many revisions as you want. We’re not happy til you’re
          happy. You can upload your footage to WeTransfer, Dropbox, or Google Drive. If you have a different service
          that
          you prefer to use, let us know.
        </p>
      </>
    ),
  },
  {
    title: 'Are the No Limits packages really unlimited?',
    content: () => (
      <>
        <p>
          The only limit is how much you can shoot. Upload as many projects as you want. We’ll work on them in the order
          they were uploaded. As soon as you approve one, we’ll start cutting the next.
        </p>
      </>
    ),
  },
  {
    title: 'I’m not sure if I’m ready to sign up…',
    content: () => (
      <>
        <p>
          Try us out for 14 days! If you don’t get a service you love, just say so before the two weeks are up, and
          we’ll give you all your money back.
        </p>
      </>
    ),
  },
  {
    title: 'Can I get multiple edits from one project?',
    content: () => (
      <>
        <p>
          Sure! You can use the same footage to make different videos. Just tell us what you need. When one is finished,
          we’ll start the next. For example, if we complete a longer video, we could then make a trailer for it.
        </p>
      </>
    ),
  },
  {
    title: 'Do you work on all types of videos?',
    content: () => (
      <>
        <p>
          Pretty much! Just: no wedding videos. No porn. No wedding-themed porn. Or porn-themed weddings. And no hate,
          no lies, no bullying. We’re not helping meanies.
        </p>
      </>
    ),
  },
  {
    title: 'Can I talk to someone?',
    content: () => (
      <>
        <p>
          We’d love to. When you’re ready to sign up, we’ll have a call and answer all your questions. If you need us to
          help you work out a creative strategy, you can book a call with one of our producers. (There may be an extra
          fee for that. We’ll let you know.)
        </p>
      </>
    ),
  },
  {
    title: 'Do you guarantee the turnaround time?\n',
    content: () => (
      <>
        <p>
          There’s a few things that could make an edit take longer. If your project has over 100 GB of raw footage, or
          you want an edit that’s more than 15 minutes, it might delay things. Special requests and unclear instructions
          could also slow us down. But we’ll do our best to get it to you fast.
        </p>
      </>
    ),
  },
  {
    title: 'Can you add stock footage and music?',
    content: () => (
      <>
        <p>
          We’ve got libraries of stock footage and music, and if you need it, we can always find something to fit your
          project. Note, videos that require extensive animation or additional asset search and selection may take
          longer to complete.
        </p>
      </>
    ),
  },
  {
    title: 'Can you write a script and record a voiceover for me?',
    content: () => (
      <>
        <p>
          We can do simple title graphics and subtitling. If you need something more complex, let us know.
        </p>
      </>
    ),
  },
  {
    title: 'I need one size for YouTube, another size for Insta, another for TikTok…',
    content: () => (
      <>
        <p>
          That’s why we’re here. Just tell us what you need and we’ll output multiple sizes from one edit. All formats
          welcome!
        </p>
      </>
    ),
  },
  {
    title: 'If I need changes, how long does it take?',
    content: () => (
      <>
        <p>
          If you submit your changes by the start of the business day, we’ll do our best to get it all done within a
          couple of hours. It could take up to 24 hours or more if you’ve got a lot of changes.
        </p>
      </>
    ),
  },
  {
    title: 'Can I get my project files?',
    content: () => (
      <>
        <p>
          If you need them, let us know. We can provide them for an admin fee.
        </p>
      </>
    ),
  },
  {
    title: 'Can I contact my editor directly?',
    content: () => (
      <>
        <p>
          You’ll be talking to the producer who will pass everything on to your editor.
        </p>
      </>
    ),
  },
  {
    title: 'What if I don’t like my editor?',
    content: () => (
      <>
        <p>
          We’ll match you up with an editor who gets your style.
        </p>
      </>
    ),
  },
  {
    title: 'What are your working hours?',
    content: () => (
      <>
        <p>
          We’re at work from Monday to Friday, 9am - 5pm EST. If you send us something near the end of the day or later,
          we’ll deal with it next morning. We’re out of the office on Canadian and US national holidays. The rest of the
          time, we’re here for you.
        </p>
      </>
    ),
  },
  {
    title: 'How many gigabytes of footage can I upload?',
    content: () => (
      <>
        <p>
          It is unlimited! Just keep in mind, the more footage you have the longer it will take to edit your video.
          Anything over 10GB, we cannot guarantee the turnaround time and may require more time to edit. A new timeline
          will be determined.
        </p>
      </>
    ),
  },

]