export const HeroTitle = () => <>
  We edit <br/>
  your videos
</>;

export const HeroContent = () => <>
  <p>
    You’ve got talent. You’ve got followers. But you don’t have time. Every second you save means more growth,
    more engagement, more you.
  </p>
  <p>
    For a flat monthly rate, we edit your videos professionally. All of them. Upload your footage and our
    creative experts do the rest.
  </p>
  <p>
    <strong>
      You bring the talent. We give you time.
    </strong>
  </p>
</>;

